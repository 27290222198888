import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Holder = styled.p`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 1rem 0;
  @media( ${props => props.theme.breakpoints.md} ) {
    padding: 2rem 0;
  }
  .h1 {
    line-height: 1;
    margin-bottom: 1rem;
  }
  > :first-child { margin-top: 0; }
  > :last-child { margin-bottom: 0; }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: -1rem;
    bottom: 0;
    border-right: 1px solid;
    @media( ${props => props.theme.breakpoints.md} ) {
      right: -2rem;
    }
  }
  > :last-child:after {
    display: none;
  }
`;

function Number({number, text}) {
    return (
      <Holder>
        <span className="h1">{number}</span>
        <span className="small"><b>{text}</b></span>
      </Holder>
    )
}

Number.propTypes = {
  number: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired
};

export default Number;
