import React from 'react';
import styled from 'styled-components';
import Container from '../atoms/Container';
import SlideInWrapper from '../molecules/SlideInWrapper';

const Holder = styled.div`
  margin: 4rem 0;
  @media( ${props => props.theme.breakpoints.md} ) {
    margin: 8rem 0;
  }
  h2 {
    margin-bottom: 4rem;
  }
`;

const NumberHolder = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
  @media( ${props => props.theme.breakpoints.md} ) {
    grid-gap: 4rem;
  }
`;

function Numbers( { children } ) {
  return (
    <Holder>
      <SlideInWrapper>
        <Container>
          <h2 className="h4 sans"><b>The project in numbers</b></h2>
          <NumberHolder>
            {children}
          </NumberHolder>
        </Container>
      </SlideInWrapper>
    </Holder>
  )
}

export default Numbers;
