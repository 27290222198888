import React from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import Image from '../atoms/Image';
import SlideInWrapper from './SlideInWrapper';

const ImageHolder = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  overflow: hidden;
`;

const ImageBg = styled.div`
  position: absolute;
  top: 1px;
  left: 1px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  z-index: 1;
  background-color: ${props => props.theme.colours.black};
`;

const ImageInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: transform 0.1s;
  background-color: ${props => props.theme.colours.grey};

  .gatsby-image-wrapper {
    height: 100%;
  }
  .gif {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

const Holder = styled.li`
  a {
    display: block;
    color: inherit;
    text-decoration: none;
  }

  @media ( ${props => props.theme.breakpoints.md} ) {
    &:hover {
      ${ImageInner} {
        transform: translate(-0.5rem, -0.5rem);
      }
    }
  }

  h2 {
    margin-top: 1rem;
    margin-bottom: 0;
  }

  p { margin: 0; }
`;

function WorkCard({work}) {
  return (
    <SlideInWrapper>
      <Holder>
        <Link to={work.slug}>
          <ImageHolder>
            <ImageBg />
            <ImageInner>
              {work.featuredImage.includes('.gif') && <img className="gif" src={`/images/${work.featuredImage}`} alt={work.title} />}
              {!work.featuredImage.includes('.gif') && <Image imgName={work.featuredImage}/>}
            </ImageInner>
          </ImageHolder>
          <h2 className="p sans"><b>{work.title}</b></h2>
          <p>{work.client}</p>
        </Link>
      </Holder>
    </SlideInWrapper>
  )
}

WorkCard.propTypes = {
  work: PropTypes.PropTypes.shape({
    title: PropTypes.string.isRequired,
    client: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    featuredImage: PropTypes.string.isRequired,
    featuredGif: PropTypes.string.isRequired,
  }),
};

export default WorkCard;
