import React from 'react';
import styled from 'styled-components';
import SlideInWrapper from './SlideInWrapper';
import Container from '../atoms/Container';
import { PropTypes } from 'prop-types';

const Holder = styled.div`
  .gatsby-image-wrapper {
    background-color: ${props => props.transparent ? 'transparent' : props.theme.colours.grey};
  }
`;

function WorkImage( { children, fullWidth, transparent } ) {
  return (
    <SlideInWrapper>
      {!fullWidth && <Container large>
        <Holder transparent={transparent}>{children}</Holder>
      </Container>}
      {fullWidth && <Holder transparent={transparent}>{children}</Holder>}
    </SlideInWrapper>
  )
}

WorkImage.propTypes = {
  fullWidth: PropTypes.bool,
  transparent: PropTypes.bool,
};

WorkImage.defaultProps = {
  fullWidth: false,
  transparent: false
};

export default WorkImage;
