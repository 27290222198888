import React from "react"
import Seo from "../components/molecules/Seo"
import Splash from "../components/organisms/Splash"
import ContentHolder from "../components/organisms/ContentHolder"
import Image from "../components/atoms/Image"
import WorkTextBlock from "../components/molecules/WorkTextBlock"
import RelatedWork from "../components/organisms/RelatedWork"
import WorkImage from "../components/molecules/WorkImage"
import ArrowText from "../components/atoms/ArrowText"
import WorkMultipleImage from "../components/molecules/WorkMultipleImage"
import Numbers from "../components/organisms/Numbers"
import Number from "../components/molecules/Number"
import thumb from "../../static/images/scb-00007.jpg"

function SCB() {
  return (
    <>
      <Seo
        title="Understanding and engaging customers | Craig Walker x SCB"
        description="Craig Walker has worked closely with Standard Chartered Bank (SCB) and their ventures teams in Singapore to help the bank tap into new opportunities across the 70 countries they operate in."
        image={thumb}
      />

      <Splash text={<>Understanding and engaging&nbsp;customers</>} work={true}>
        <p>
          Standard Chartered Bank (SCB) is one of the world’s oldest banks and
          its operations in Singapore are home to their global business
          leadership, technology operations and innovation hub. Craig Walker has
          worked closely with their innovation and ventures teams in Singapore
          on a series of projects to help the bank understand and engage with
          their customers and tap into new opportunities across the 70 countries
          they operate in.
        </p>
        <ArrowText>
          <p>Learn More</p>
        </ArrowText>
      </Splash>

      <ContentHolder>
        <WorkImage>
          <Image imgName="scb-00011.jpg" />
        </WorkImage>

        <Numbers>
          <Number number={63} text="Customer interviews" />
          <Number number={29} text="Perfect services" />
          <Number number={5} text="Venture design programmes supported" />
        </Numbers>

        <WorkTextBlock>
          <h2>Reaching the next&nbsp;generation</h2>
          <p>
            The transfer and ownership of wealth in Singapore is a strategic
            focus for SCB, as families in the region usually have strong
            inter-generational ties. Craig Walker helped SCB understand the
            dynamics of this market and build a perspective on how to attract
            the next generation of wealth customers to the bank. Using a range
            of interviews, observation of families and deep discussions with
            family ‘elders,’ we identified the key moments, habits and product
            mix to attract the next generation of SCB’s customer base.
          </p>
        </WorkTextBlock>

        <WorkMultipleImage images={["scb-00007.jpg", "scb-00008.jpg"]} />
        <WorkMultipleImage images={["scb-00009.jpg", "scb-00010.jpg"]} />

        <WorkTextBlock>
          <h2>Tapping new&nbsp;sectors</h2>
          <p>
            SCB wanted to quickly understand the rapidly growing Indian
            healthcare market to identify opportunities and develop new
            strategic directions for the sector. Our team travelled to India to
            undertake ethnographic research of the local healthcare ecosystem,
            observing and interviewing 50 people, eight hospitals, one insurance
            company and a third-party administrator. From the research, we
            developed a set of outputs for the eXellerator team to drive
            innovation and strategic solutions workshops.
          </p>
        </WorkTextBlock>

        <WorkMultipleImage images={["scb-00001.jpg", "scb-00006.jpg"]} />
        <WorkMultipleImage images={["scb-00005.jpg", "scb-00002.jpg"]} />

        <WorkTextBlock>
          <h2>Activating a latent customer&nbsp;base</h2>
          <p>
            SCB’s Group Wealth team needed to understand how to engage and
            better serve the 80% of their priority banking customers who are
            largely inactive. Our research team spent time with 12 priority
            banking customers in order to understand how they live, what’s
            important to them, their priorities around money and investing and
            how they might use the bank’s digital services. We turned the
            research outcomes into a set of tools, including customer
            preferences, future state journey maps and personas, to help the
            business Group Wealth team find quick wins and prioritise a roadmap
            for a global product with localised services.
          </p>
        </WorkTextBlock>

        <RelatedWork exclude="scb" />
      </ContentHolder>
    </>
  )
}

export default SCB
