import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Container from '../atoms/Container';
import { workList } from '../../data/workList';
import WorkCard from '../molecules/WorkCard';
import PropTypes from 'prop-types';
import ArrowText from '../atoms/ArrowText';

const Holder = styled.div`
  margin: 4rem 0;
  @media( ${props => props.theme.breakpoints.sm} ) {
    margin: 4rem 0;
  }
`;

const WorkHolder = styled.ul`
  list-style: none;
  padding-left: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  margin-top: 4rem;
  @media( ${props => props.theme.breakpoints.sm} ) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem;
  }
`;

function RelatedWork({exclude}) {

  const [projects, setProjects] = useState(null);

  useEffect(() => {
    // Exclude current item
    let work = workList;
    work = work.filter(item => item.id !== exclude);

    // Shuffle workList
    const shuffled = work.sort(() => 0.5 - Math.random());

    // Get sub-array of first n elements after shuffled
    setProjects( shuffled.slice(0, 6) );
  }, [exclude]);

  return (
    <Holder>
      <Container>
        <ArrowText>
          <p><b>More work</b></p>
        </ArrowText>
        <WorkHolder>
          {projects && projects.map(work => <WorkCard key={work.slug} work={work}/>)}
        </WorkHolder>
      </Container>
    </Holder>
  )
}

RelatedWork.propTypes = {
  exclude: PropTypes.string.isRequired,
};

export default RelatedWork;
