import React, { useEffect, useRef, useState } from "react"
import _uniqueId from "lodash/uniqueId"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

function SlideInWrapper({ children, earlyStartTrigger }) {
  const [id, setId] = useState(null)
  const holder = useRef(null)
  const tl = useRef(null)

  const startTriggerVal = earlyStartTrigger ? "bottom" : "100%"

  useEffect(() => {
    setId(_uniqueId("slide-in-wrapper-"))
  }, [])

  useEffect(() => {
    if (!tl.current && id) {
      tl.current = ScrollTrigger.matchMedia({
        // desktop
        "(min-width: 576px)": function () {
          ScrollTrigger.saveStyles(`#${id}`)
          gsap
            .timeline({
              scrollTrigger: {
                trigger: holder.current,
                start: `top ${startTriggerVal}`,
                end: "top 75%",
                scrub: true,
              },
            })
            .from(`#${id}`, {
              yPercent: 100,
              autoAlpha: 0,
              duration: 1,
            })
        },
      })
    }
  }, [id, startTriggerVal])

  return (
    <div ref={holder}>
      <div id={id}>{children}</div>
    </div>
  )
}

export default SlideInWrapper
