import React from "react"
import styled from "styled-components"
import Container from "../atoms/Container"
import SlideInWrapper from "./SlideInWrapper"

const Holder = styled.div`
  width: 100%;
  margin: 4rem 0;
  @media (${(props) => props.theme.breakpoints.md}) {
    width: 50%;
    margin: 10rem 0;
  }
  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }

  h2 {
    font-family: "Karla", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Helvetica, Arial, sans-serif;
    font-weight: bold;
    ${(props) => props.theme.fluidType(4)};
    p {
      margin-left: 1rem;
      font-weight: 500;
      margin-top: 0.5rem;
    }
  }
  img {
    width: 100%;
    display: block;
    margin-bottom: 2rem;
  }
`

function WorkTextBlock({ children }) {
  return (
    <Container>
      <SlideInWrapper>
        <Holder>{children}</Holder>
      </SlideInWrapper>
    </Container>
  )
}

export default WorkTextBlock
